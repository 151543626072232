<template>
  <!-- 登录页面 -->
  <div>
    <!-- <div v-if="isMobile">
      1
    </div> -->
    <!-- {{ params }}

    <br/>
    {{ result }} -->
    <div class="page_bg mobile_bg">
      <div class="content">
        <img class="logo" src="@/assets/images/zhineng_logo.png" alt="" />
        <div class="title">智能化多媒体管理和生产系统</div>
        <div class="form">
          <div class="form_inner" @click="getData">
            <el-form ref="form" :model="form" :rules="rules">
              <!-- <el-form-item prop="email" :error="error1" show-message>
                <el-input v-model="form.email" size="big" placeholder="邮 箱" @keyup.enter.native="submit()"></el-input>
              </el-form-item>
              <el-form-item prop="password" :error="error2" show-message>
                <el-input v-model="form.password" type="password" size="big" placeholder="密 码" @keyup.enter.native="submit()"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="submit_btn" size="big" @click="submit()">登录</el-button>
              </el-form-item> -->
              <!-- <el-form-item class="bottom_link" v-if="!isMobile">
                <router-link class="router_link" target="_blank" :to="{ name: 'FindPassword' }">忘记密码</router-link>
                <router-link class="router_link" :to="{ name: 'Register' }">没有账号 ?立即注册</router-link>
              </el-form-item> -->
               <img src="@/assets/images/douyin.png" alt=""/>
               <p>点击进行抖音授权登录</p>
            </el-form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
// import { getScreen } from "../plugins/solvemethod";
import md5 from 'js-md5';
export default {
  data() {
    return {
      isMobile: false, // false：pc，true： 移动端
      form: {
        email: '',
        password: ''
      },
      error1: '',
      error2: '',
      rules: {
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          {
            type: 'email',
            message: '请填写正确邮箱',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          {
            min: 6,
            message: '密码6位以上',
            trigger: 'blur',
          },
        ],
      },
      params: '',
      result: ''
    };
  },
  created () {
    // this.geAauth('ticket')
  },
  methods: {

    getData () {
      let url = ''
      if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
       url =  location.href
      }else{
        url = window.location.href
      }
      this.$http({
        url: "/release/douyin_js_params",
        method: "POST",
        data: {url: url}
      }).then((res) => {
        // alert('res')
        // alert(res)
        if (res.data.status == 0) {
              const sdk = window.DouyinOpenJSBridge;
              let params = {
                client_key: 'awaravgl5hpt7b83', // clientKey在你的网页应用申请通过后得到
                signature: res.data.res.signature, // 服务端计算的签名，该签名被抖音开放平台验证通过后方可调用jsb方法
                timestamp: res.data.res.timestamp,// 时间戳
                nonce_str: res.data.res.nonce_str,
                url: url, // 为应用申请的 JSB 安全域名下的链接，需要携带协议。e.g.  https://jsb.security.domain/page.html
              }
              this.params = params
              sdk.config({params: params});
              sdk.ready((res) => {
                sdk.jumpOpenAuth({
                  params: {
                    client_key: 'awaravgl5hpt7b83',
                    // redirect_uri: window.location.href,
                    state: 'douyin_new',
                    // scope: 'user_info,video.data.bind,video.list.bind,h5.share,open.get.ticket',
                    scope: 'user_info,h5.share,open.get.ticket',

                    // scope: 'user_info,aweme.share,renew_refresh_token,data.external.billboard_topic,video.data.bind,video.list.bind,h5.share,open.get.ticket',

                    response_type: 'code'
                  },
                  success: ( { ticket } ) => {
                    // alert('sucess')
                    this.result = ticket
                    this.geAauth(ticket)
                    // this.$router.push('/')
                  },
                  // error: res => alert(res)
                  error: res => console.log(res)

                });

              });
              sdk.error(res => {
                  // Config error回调
                console.log(Object.values(res));

              });
        }
      }).catch((error) => {
        // alert('error')
        // alert(error)
        console.log(error)
      });
    },

    geAauth (code) {
      this.$http({
        url: "/release/authorization_success/",
        method: "GET",
        params: {
          code: code,
          state: 'douyin_new'
        }
      }).then((res) => {
        console.log(res)
        if (res.data.status == 0) {
          this.$store.commit("setOpenId",res.data.open_id)
          this.$router.replace({
            path: "/work_library"
          });
        }
      }).catch((error) => console.log(error));
    },
  },
};
</script>
<style lang="scss" scoped>
  .page_bg{
    width: 100%;
    height: 100vh;
    position: relative;
    color: $white9;
    .content{
      text-align: center;
      .logo{
        width: 48%;
        margin-bottom: 6px;
      }
      .title{
        letter-spacing: 4px;
      }
      .form{
        background: rgba(57,56,70,0.39);
        border-radius: 5px;
        @include flex-align-justify(center, center);
        .form_inner{
          // text-align: left;
          .form_title{
            margin-bottom: 30px;
          }
          .bottom_link{
            /deep/.el-form-item__content{
              @include flex-align-justify(center, space-between);
              &:before,&:after{
                content: inherit;
              }
            }
          }
          .submit_btn{
            width: 100%;
            letter-spacing: 50px;
            /deep/span{
              padding-left: 50px;
            }
          }
        }
      }
    }
  }
  .mobile_bg{
    background: url("../assets/images/mobile_login.png") no-repeat;
    background-size: cover;
    .content{
      padding-top: 40%;
      .title{
        font-size: 18px;
      }
    }
    .form{
      width: 80%;
      padding: 30px 0;
      margin: 0 auto;
      margin-top: 40px;
      .form_inner{
        p{
          margin-top: 10px;
        }
        // width: 84%;
      }
    }
  }
</style>
