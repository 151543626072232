// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/mobile_login.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page_bg[data-v-15717af5] {\n  width: 100%;\n  height: 100vh;\n  position: relative;\n  color: rgba(255, 255, 255, 0.9);\n}\n.page_bg .content[data-v-15717af5] {\n    text-align: center;\n}\n.page_bg .content .logo[data-v-15717af5] {\n      width: 48%;\n      margin-bottom: 6px;\n}\n.page_bg .content .title[data-v-15717af5] {\n      letter-spacing: 4px;\n}\n.page_bg .content .form[data-v-15717af5] {\n      background: rgba(57, 56, 70, 0.39);\n      border-radius: 5px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n}\n.page_bg .content .form .form_inner .form_title[data-v-15717af5] {\n        margin-bottom: 30px;\n}\n.page_bg .content .form .form_inner .bottom_link[data-v-15717af5] .el-form-item__content {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n}\n.page_bg .content .form .form_inner .bottom_link[data-v-15717af5] .el-form-item__content:before, .page_bg .content .form .form_inner .bottom_link[data-v-15717af5] .el-form-item__content:after {\n          content: inherit;\n}\n.page_bg .content .form .form_inner .submit_btn[data-v-15717af5] {\n        width: 100%;\n        letter-spacing: 50px;\n}\n.page_bg .content .form .form_inner .submit_btn[data-v-15717af5] span {\n          padding-left: 50px;\n}\n.mobile_bg[data-v-15717af5] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n}\n.mobile_bg .content[data-v-15717af5] {\n    padding-top: 40%;\n}\n.mobile_bg .content .title[data-v-15717af5] {\n      font-size: 18px;\n}\n.mobile_bg .form[data-v-15717af5] {\n    width: 80%;\n    padding: 30px 0;\n    margin: 0 auto;\n    margin-top: 40px;\n}\n.mobile_bg .form .form_inner p[data-v-15717af5] {\n      margin-top: 10px;\n}\n", ""]);
// Exports
module.exports = exports;
