var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page_bg mobile_bg" }, [
      _c("div", { staticClass: "content" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/images/zhineng_logo.png"), alt: "" }
        }),
        _c("div", { staticClass: "title" }, [
          _vm._v("智能化多媒体管理和生产系统")
        ]),
        _c("div", { staticClass: "form" }, [
          _c(
            "div",
            { staticClass: "form_inner", on: { click: _vm.getData } },
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/douyin.png"),
                      alt: ""
                    }
                  }),
                  _c("p", [_vm._v("点击进行抖音授权登录")])
                ]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }